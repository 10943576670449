import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/triangle-right.svg'


const _hoisted_1 = {
  key: 0,
  class: "search-triangle",
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.leftIcon)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_AutoComplete, {
      forceSelection: "",
      modelValue: this.input,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.input) = $event)),
      suggestions: this.currentView == 'Pos' ? null : this.options,
      ref: "autocomplete",
      field: this.label,
      delay: 1500,
      placeholder: this.placeHolder,
      onComplete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItem($event))),
      onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.searchItem($event)), ["enter"])),
      onItemSelect: _cache[3] || (_cache[3] = ($event: any) => (_ctx.lookup($event)))
    }, null, 8, ["modelValue", "suggestions", "field", "placeholder"])
  ], 64))
}