
import { defineComponent } from "vue";
import AutoComplete from "primevue/autocomplete";
import CustomerService from "@/services/CustomerService";
import PartsService from "@/services/PartsService";
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
export default defineComponent({
  name: "Search",
  components: {
    AutoComplete,
  },
  computed: {
    ...mapGetters({
      getCustomer: "customer/getCust",
    }),
  },
  methods: {
    ...mapActions({
      fetchCustomer: "customer/fetchCustomer",
      fetchPart: "part/fetchPart",
    }),
    lookup($event: any) {
      switch (this.file) {
        case "CUST":
          this.getCust($event);
          break;
        case "Bill":
          this.$emit("filterCust", $event);
          break;
        case "NEWSALES":
          this.$emit("filterCust", $event);
          break;
        case "Invoice":
          this.$emit("setCust", $event);
          break;
        case "PARTS":
          this.$emit("filterPart", $event);
          break;
        case "PRICE":
          this.$emit("filterPrice", $event);
          break;
        case "Sale":
          this.$emit("filterCust", $event);
          break;
        case "Inventory":
          this.getPart($event);
          break;
        default:
          break;
      }
    },
    searchItem(element: any) {
      this.$emit("isSearching", true);
      switch (this.file) {
        case "CUST":
          this.searchLookup();
          break;
        case "Bill":
          this.searchLookup();
          break;
        case "NEWSALES":
          this.searchLookup();
          break;
        case "Invoice":
          this.searchLookup();
          break;
        case "Sale":
          this.searchLookup();
          break;
        case "PARTS":
          this.lookupPart();
          break;
        case "Inventory":
          this.lookupPart();
          break;
        default:{
            const searchInput = (element.value) ? element.value : element.query ? element.query : this.input;
            this.searchFunction && this.searchFunction(searchInput, this);
            break;
          }
      }
    },
    getCust(element: any) {
      this.fetchCustomer({
        id: element.value.cust_id,
      }).then(() => {
        this.input = "";
      });
    },
    getPart(element: any) {
      this.fetchPart({
        partId: element.value.part_no,
      }).then(() => {
        this.input = "";
      });
    },
    searchLookup() {
      const service = new CustomerService(process.env.VUE_APP_ABSTRACTION_API);
      service.fetchCustOptions(this.input).then((response: any) => {
        if (response.cust_items.length === 1 && this.file === "CUST") {
          this.getCust({
            value: {
              ...response.cust_items[0],
            },
          });
          this.options = [];
        } else {
          this.options = response.cust_items;
        }
      });
    },
    lookupPart() {
      const service = new PartsService(process.env.VUE_APP_ABSTRACTION_API);
      service
        .getPartsBySelection(this.input, "part_no")
        .then((response: any) => {
          if (response.parts_items.length === 1 && this.file === "Inventory") {
            this.getPart({
              value: {
                ...response.parts_items[0],
              },
            });
            this.options = [];
          } else {
            this.options = response.parts_items;
          }
        });
    },
    lookupPrice() {
      if(this.currentView == 'Pos' && this.input) {
        const partSearch = this.input;
        const service = new PartsService(process.env.VUE_APP_ABSTRACTION_API);
        service.getPrices(
          store.getters["session/getClient"],
          this.input,
          "",
          this.getCustomer.code || this.getCustomer.cust_id,
          "",
          this.getCustomer.cust_id,
          this.rangeStart.toString(),
          this.rangeEnd.toString()
        ).then((response: any) => {
          this.$emit("priceSearch", {search: partSearch, parts: response.price_items});
        }).finally(() => {
          (this.$refs.autocomplete as any).searching = false;
        });
      }
    },
  },
  data() {
    return {
      input: "",
      options: [],
    };
  },
  props: {
    label: String,
    file: String,
    placeHolder: String,
    currentView: String,
    searchFunction: Function,
    leftIcon: {
      type: Boolean,
      default: false,
    },
    rangeEnd: {
      type: Number,
      default: 100,
    },
    rangeStart: {
      type: Number,
      default: 1,
    },
  },
});
